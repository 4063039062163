import React from "react";
import "./company-location.css";
import mapFake from "../../../../assets/images/image.png"
import { MobileSubscritptionInput } from "../../../../components/mobile-subscription-input/MobileSubscriptionInput";
import { MobileActionButton } from "../../../../components/mobile-action-button/MobileActionButton";

const CompanyLocation = () => {
    return (
        <div className="locationCompany">
            <h4>Dirección</h4>
            <img src={mapFake} alt="" />
            <section className="form-location-company">
                <MobileSubscritptionInput label={'Dirección de la Empresa'} />
                <MobileSubscritptionInput label={'Ciudad'} />
                <MobileSubscritptionInput label={'Estado'} />
                <MobileSubscritptionInput label={'País'} />
                <MobileSubscritptionInput label={'Código Postal'} />
                <MobileSubscritptionInput label={'Teléfono de Contacto'} />
                <MobileSubscritptionInput label={'Correo Electrónico'} />
            </section>
            <section className="buttons-location-company">
                <MobileActionButton text={'Cancelar'} cancel={true} />
                <MobileActionButton text={'Guardar'} />
            </section>
        </div>
    )
}

export { CompanyLocation }