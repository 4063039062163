import React from "react";
import "./form-buttons-desktop.css";

const FormButtonsDesktop = ({
  textDark,
  textGreen,
  onClickDark,
  onClickGreen,
}) => {
  return (
    <div className="btnsDesk-subs">
      <button onClick={onClickDark}>{textDark}</button>
      <button onClick={onClickGreen}>{textGreen}</button>
    </div>
  );
};

export { FormButtonsDesktop };
