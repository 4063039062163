import React from "react";

const Payments = () => {
    return (
        <>
            <div>
                Pagos
            </div>
        </>
    )
}

export { Payments }