import React from "react";
import { useNavigate } from "react-router-dom";
import './side-bar.css';
import close from '../../assets/icons/close.svg';
import profile from '../../assets/icons/person-outline.svg';
import tray from '../../assets/icons/mail-outline.svg';
import settings from '../../assets/icons/settings-outline.svg';
import home from '../../assets/icons/home-outline.svg';
import employees from '../../assets/icons/people-outline.svg';
import subscriptions from '../../assets/icons/arrow-up-circle.svg';
import payments from '../../assets/icons/wallet-outline.svg';
import trash from '../../assets/icons/trash-outline.svg';
import texts from '../../texts.json';

const SideBar = ({ toggleSideBar }) => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
        toggleSideBar(false);
    };

    return (
        <section className="section-sidebar">
            <div className="main-sidebar-section">
                <div className="business-profile-header">
                    <div className="sidebar-brand-logo">
                        <div></div>
                        <h1>{texts.common.brand}</h1>
                    </div>
                    <button onClick={() => toggleSideBar(false)}>
                        <img src={close} alt="close" />
                    </button>
                </div>
                <div className="company-logo-name">
                    <div></div>
                    <p>Empresa S.A. de C.V.</p>
                </div>
                <div className="section-sidebar-options">
                    <div onClick={() => handleNavigation('/home/profile')}>
                        <img src={profile} alt="" />
                        <span>{texts.components.navBar.profile}</span>
                    </div>
                    <div>
                        <div>
                            <img src={tray} alt="" />
                            <span>{texts.components.navBar.inbox}</span>
                        </div>
                        <p>3</p>
                    </div>
                    <div>
                        <img src={settings} alt="" />
                        <span>{texts.components.navBar.configuration}</span>
                    </div>
                </div>
                <div className="section-sidebar-nav">
                    <div onClick={() => handleNavigation('/home')}>
                        <img src={home} alt="home" />
                        <span>{texts.components.sidebar.nav.home}</span>
                    </div>
                    <div onClick={() => handleNavigation('/employees')}>
                        <img src={employees} alt="employees" />
                        <span>{texts.common.employees}</span>
                    </div>
                    <div onClick={() => handleNavigation('/subscriptions')}>
                        <img src={subscriptions} alt="subscriptions" />
                        <span>{texts.components.sidebar.nav.subscriptions}</span>
                    </div>
                    <div onClick={() => handleNavigation('/payments')}>
                        <img src={payments} alt="payments" />
                        <span>{texts.common.myPayments}</span>
                    </div>
                </div>
            </div>
            <div className="section-sidebar-paper">
                <div onClick={() => handleNavigation('/paper-bin')}>
                    <img src={trash} alt="trash" />
                    <span>{texts.components.sidebar.paperBin}</span>
                </div>
            </div>
        </section>
    );
}

export { SideBar };
