import React from "react";
import './base-view.css';

const BaseView = ({ children }) => {
    return (
        <div className='base-view-main'>
            {children}
        </div>
    );
}

export { BaseView };
