// enterpriseApiSlice.js
import { apiSlice } from '../../api/apiSlice';

export const enterpriseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createEnterprise: builder.mutation({
      query: (body) => ({
        url: '/enterprise',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Enterprise'],
    }),
    getEnterprise: builder.query({
      query: () => "/enterprise",
      keepUnusedDataFor: 60,
      providesTags: ['Enterprise'],
    }),
    getEnterpriseProfile: builder.query({
      query: () => "/enterprise/profile",
      keepUnusedDataFor: 60,
      providesTags: ['Enterprise'],
    }),
    getEnterpriseEmployees: builder.query({
      query: () => "/enterprise/employees",
      keepUnusedDataFor: 60,
      providesTags: ['Enterprise'],
    }),
    approveEmployee: builder.mutation({
      query: (employeeId) => ({
        url: `employee/${employeeId}/approve`, // Remove the template literal
        method: 'PATCH',
      }),
      // Invalidar el cache de los empleados cuando se apruebe uno
      invalidatesTags: ['Employees']
    })
  }),
});

export const {
  useCreateEnterpriseMutation,
  useGetEnterpriseQuery,
  useGetEnterpriseProfileQuery,
  useGetEnterpriseEmployeesQuery,
  useApproveEmployeeMutation,
} = enterpriseApiSlice;
