import React from "react";
import "./mobile-subscription-input.css";

const MobileSubscritptionInput = ({ label }) => {
    return (
        <div className="inputMobileSubs">
            <label>{label}</label>
            <input />
        </div>
    )
}

export { MobileSubscritptionInput }