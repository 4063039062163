import React from 'react';
import './fab-button.css';

const FabButton = ({ imgSrc, onClick }) => {
    return (
        <button className="fab-button" onClick={onClick}>
            <img src={imgSrc} alt="FAB icon" />
        </button>
    );
};

export { FabButton };
