import React, { useEffect, useState } from "react";
import "./user-feedback-styles.css";

const UserFeedback = ({ message, type }) => {
  const [feedback, setFeedback] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setFeedback(false);
    }, 3000); // Desaparece en 3 segundos

    return () => clearTimeout(timer);
  }, [feedback]);
  return (
    <>
      {feedback && <div className={`feedback-message ${type}`}>{message}</div>};
    </>
  );
};
export { UserFeedback };
