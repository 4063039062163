import React from "react";
import "./desktop-subs-input.css";

const DesktopSubsInput = ({ label, style }) => {
    return (
        <div className="deskSubs-input" style={style}>
            <label>{label}</label>
            <input />
        </div>
    )
}

export { DesktopSubsInput }