import React from "react";
import "./settings-function.css";
import { MobileActionButton } from "../mobile-action-button/MobileActionButton";
import cardIcon from "../../assets/icons/wallet-outline.svg";
import { GreenBorderButton } from "../green-border-button/GreenBorderButton";
import useWindowWidth from "../../hooks/useWindowWidth";

const SettingsFunction = ({ title, description, buttonText, payment }) => {
  const windowWidth = useWindowWidth();
  return (
    <div className="settings-function">
      <section>
        <label>{title}</label>
        <p>{description}</p>
      </section>
      {payment ? (
        <div className="payment-card">
          <div className="icon-labels-card">
            <img src={cardIcon} alt="" />
            <section className="label-card">
              <label>MasterCard</label>
              <div className="data-card-label">
                <label>••••0123</label>
                <label>03/29</label>
              </div>
            </section>
          </div>
          <div className="buttons-card">
            <button>Editar</button>
            <button>Eliminar</button>
          </div>
        </div>
      ) : windowWidth <= 480 ? (
        <MobileActionButton cancel={true} text={buttonText} />
      ) : (
        <div className="button-container-web">
          <GreenBorderButton border={true} text={buttonText} />
        </div>
      )}
    </div>
  );
};

export { SettingsFunction };
