import React from "react";
import "./advanced-configuration.css";
import goBackIcon from "../../../../assets/icons/go-back.svg";
import { useNavigate } from "react-router-dom";
import { SettingsSectionTitle } from "../../../../components/settings-section-title/SettingsSectionTitle";
import { SettingsFunction } from "../../../../components/settings-function/SettingsFunction";

const AdvancedConfiguration = () => {
  const navigate = useNavigate();
  return (
    <div className="advanced-configuration-movil">
      <img src={goBackIcon} alt="" onClick={() => navigate("/home/profile")} />
      <SettingsSectionTitle label={"Configuración Avanzada"} />
      <SettingsFunction
        title={"Eliminar mi cuenta"}
        description={
          "Al eliminar tu cuenta, ya no podrás acceder a la información ingresada. Tu cuenta de POOL-E se creó a las 8:17 a. m. del 31 de julio de 2023."
        }
        buttonText={"Eliminar cuenta"}
      />
    </div>
  );
};

export { AdvancedConfiguration };
