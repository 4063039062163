import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRegisterEmployeeMutation } from '../../../features/employee/employeeApiSlice';
import './register-employee.css'

const RegisterEmployee = () => {
  const navigate = useNavigate();
  const [registerEmployee, { isLoading }] = useRegisterEmployeeMutation();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: ''
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Preparar los datos según el formato esperado por el backend
      const employeeData = {
        name: `${formData.nombres} ${formData.apellidos}`, // Combinar nombres y apellidos
        email: formData.correo,
        phone: formData.telefono,
        address: formData.direccion // Dirección simple como string
      };

      // Llamar a la mutación
      const result = await registerEmployee(employeeData).unwrap();
      console.log('Employee registered:', result);

      // Redireccionar a la lista de empleados
      navigate('/employees');
    } catch (err) {
      console.error('Failed to register employee:', err);
      setError(err.data?.message || 'Error al registrar empleado');
    }
  };

  const handleCancel = () => {
    navigate('/employees');
  };

  return (
    <>
      <div className="container-register-employee">
        <div className="employee-photo-register-container">
          <div></div>
          <label>Id de Empleado</label>
          <span>0001</span>
        </div>
        <div className="employee-registration-form-container">
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group-employee-registration">
              <label htmlFor="nombres">Nombres</label>
              <input
                type="text"
                id="nombres"
                name="nombres"
                value={formData.nombres}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-employee-registration">
              <label htmlFor="apellidos">Apellidos</label>
              <input
                type="text"
                id="apellidos"
                name="apellidos"
                value={formData.apellidos}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-employee-registration">
              <label htmlFor="correo">Correo</label>
              <input
                type="email"
                id="correo"
                name="correo"
                value={formData.correo}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-employee-registration">
              <label htmlFor="telefono">Teléfono</label>
              <input
                type="tel"
                id="telefono"
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-employee-registration">
              <label htmlFor="direccion">Dirección</label>
              <input
                type="text"
                id="direccion"
                name="direccion"
                value={formData.direccion}
                onChange={handleChange}
                required
              />
            </div>
            <div className="employee-registration-button-container">
              <button
                type="button"
                onClick={handleCancel}
                disabled={isLoading}
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Guardando...' : 'Guardar Empleado'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { RegisterEmployee }
