import React from "react";
import "./settings-section-title.css";

const SettingsSectionTitle = ({ label }) => {
  return (
    <div className="settings-section-title">
      <label>{label}</label>
    </div>
  );
};

export { SettingsSectionTitle };
