import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateEnterpriseMutation } from '../../../features/enterprise/enterpriseApiSlice';
import './sign-up.css';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.empresa);
  const [createEmpresa, { isLoading }] = useCreateEnterpriseMutation();


  const [formData, setFormData] = useState({
    companyName: '',
    representativeName: '',
    email: '',
    password: '',
    plan: '',
    acceptTerms: false,
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.companyName) newErrors.companyName = 'El nombre de la empresa es obligatorio';
    if (!formData.representativeName) newErrors.representativeName = 'El nombre del representante es obligatorio';
    if (!formData.email) {
      newErrors.email = 'El correo es obligatorio';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'El correo no es válido';
    }
    if (!formData.password) {
      newErrors.password = 'La contraseña es obligatoria';
    } else if (formData.password.length < 8) {
      newErrors.password = 'La contraseña debe tener al menos 8 caracteres';
    }
    if (!formData.plan) newErrors.plan = 'Elige un plan de interés';
    if (!formData.acceptTerms) newErrors.acceptTerms = 'Debes aceptar los términos y condiciones';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Clear previous errors

    if (validate()) {
      try {
        const empresaData = {
          companyName: formData.companyName,
          representativeName: formData.representativeName,
          email: formData.email,
          password: formData.password,
        };
        console.log('Submitting data:', empresaData);


        const result = await createEmpresa(empresaData).unwrap();
        console.log('Account creation successful:', result);

        navigate('/login');
      } catch (error) {
        console.error('Error creating account:', error);
        setErrors(prevErrors => ({
          ...prevErrors,
          submit: error.data?.message || 'Error creating account. Please try again.'
        }));
      }
    } else {
      console.log('Validation failed');
    }
  };

  return (
    <>
      <div className='account-header'>
        <div></div>
        <h1>name</h1>
      </div>
      <div className='title-registration'>
        <h2>Crear Cuenta</h2>
      </div>
      <div className='registration-form-container'>
        <form onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <label>Nombre de Empresa</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className={errors.companyName ? 'input-error' : ''}
            />
            {errors.companyName && <p className="error-message">{errors.companyName}</p>}
          </div>

          <div className="form-group">
            <label>Nombre de Representante</label>
            <input
              type="text"
              name="representativeName"
              value={formData.representativeName}
              onChange={handleChange}
              className={errors.representativeName ? 'input-error' : ''}
            />
            {errors.representativeName && <p className="error-message">{errors.representativeName}</p>}
          </div>

          <div className="form-group">
            <label>Correo</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? 'input-error' : ''}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>

          <div className="form-group">
            <label>Contraseña</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={errors.password ? 'input-error' : ''}
            />
            {errors.password && <p className="error-message">{errors.password}</p>}
          </div>

          <div className="form-group">
            <label>Plan de Interés</label>
            <select
              name="plan"
              value={formData.plan}
              onChange={handleChange}
              className={errors.plan ? 'input-error' : ''}
            >
              <option value="">Selecciona un plan</option>
              <option value="basic">Plan Básico</option>
              <option value="pro">Plan Pro</option>
              <option value="enterprise">Plan Empresarial</option>
            </select>
            {errors.plan && <p className="error-message">{errors.plan}</p>}
            <p className="note">No se realizará ningún cobro</p>
          </div>

          <div className="form-group checkout">
            <label>
              <input
                type="checkbox"
                name="acceptTerms"
                checked={formData.acceptTerms}
                onChange={handleChange}
              />
              Acepto los Términos de servicio, la Política de privacidad y la<br />Configuración de notificaciones predeterminada de<br />(NOMBRE).
            </label>
            {errors.acceptTerms && <p className="error-message">{errors.acceptTerms}</p>}
          </div>

          {errors.submit && <p className="error-message">{errors.submit}</p>}

          <button
            type="submit"
            className="btn-submit"
            disabled={status === 'loading'}
          >
            {status === 'loading' ? 'Creando cuenta...' : 'Crear cuenta'}
          </button>
          <p className='registration-question'>¿Ya tienes una cuenta?</p>
          <button
            type="button"
            className="btn-login"
            onClick={() => navigate('/login')}
          >
            Iniciar Sesión
          </button>
        </form>
      </div>
    </>
  );
};

export { SignUp };
