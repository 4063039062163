import React, { useState } from "react";
import "./payment-method.css";
import createNewIcon from "../../../assets/icons/add-circle.svg";
import emptyImage from "../../../assets/images/empty.png";
import cardIcon from "../../../assets/icons/wallet-outline.svg";
import rightIcon from "../../../assets/icons/right.svg";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { useNavigate } from "react-router-dom";
import { ViewTitleAndBackButton } from "../../../components/view-title-and-back-button/ViewTitleAndBackButton";
import { MobileActionButton } from "../../../components/mobile-action-button/MobileActionButton";

const PaymentMethod = () => {
  const [
    empty,
    // setEmpty
  ] = useState(false);
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="view-payment-method"
        style={{
          width: windowWidth >= 1200 ? "70%" : "100%",
          top: empty ? "43%" : "10%",
        }}
      >
        <div className="header-and-create-new-button">
          <label>Método de Pago</label>
          <div
            className="create-new-button"
            onClick={() => navigate("/home/new-payment-method")}
          >
            <img src={createNewIcon} alt="create new" />
            <label>Crear Nuevo</label>
          </div>
        </div>
        <div>
          {empty && (
            <div className="empty-payment-method">
              <img src={emptyImage} alt="empty" />
              <p>No hay método de pago guardado</p>
            </div>
          )}
          {!empty && (
            <div className="card-payment-method">
              <div className="payment-card-data">
                <img src={cardIcon} alt="" />
                <div>
                  <label>
                    <strong>MasterCard |</strong> Nombre Apellido TDC
                  </label>
                  <div className="card-number-and-expiration-date">
                    <span>.... 0123</span>
                    <span>03/29</span>
                  </div>
                </div>
              </div>
              <button onClick={() => navigate("/home/purchase-detail")}>
                Seleccionar
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className="view-mobile-payment-method"
        style={{ height: empty ? "" : "85vh" }}
      >
        <ViewTitleAndBackButton title={"Método de Pago"} />
        {empty && (
          <div className="empty-payment-method-on-mobile">
            <img src={emptyImage} alt="empty" />
            <p>No hay método de pago guardado</p>
          </div>
        )}
        {!empty && (
          <div className="mobile-card-payment-method">
            <div className="card-icon-containerfor-mobile-payment-method">
              <img src={cardIcon} alt="cardIcon" />
            </div>
            <div className="mobile-payment-method-information">
              <label>MasterCard</label>
              <div
                className="payment-card-holder"
                onClick={() => navigate("/home/purchase-detail")}
              >
                <label>Nombre Apellido TDC</label>
                <img src={rightIcon} alt="right arrow" />
              </div>
              <div
                className="mobile-payment-method-card-number-and-expiration-date"
                onClick={() => navigate("/home/purchase-detail")}
              >
                <label>.... 0123</label>
                <label>03/29</label>
              </div>
              <button className="delete-payment-method-button-on-mobile">
                Eliminar
              </button>
            </div>
          </div>
        )}
        <div
          style={{
            position: empty ? "" : "absolute",
            bottom: empty ? "" : 0,
            display: empty ? "" : "flex",
            justifyContent: empty ? "" : "center",
            alignItems: empty ? "" : "center",
            width: empty ? "" : "85%",
          }}
        >
          <MobileActionButton
            text={"Nuevo"}
            onClick={() => navigate("/home/new-payment-method")}
          />
        </div>
      </div>
    </>
  );
};

export { PaymentMethod };
