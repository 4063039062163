import React from "react";
import "./payment-history.css";
import goBackIcon from "../../../../../assets/icons/go-back.svg";
import { SettingsSectionTitle } from "../../../../../components/settings-section-title/SettingsSectionTitle";
import { PaymentHistoryItem } from "../../../../../components/payment-history-item/PaymentHistoryItem";
import { useNavigate } from "react-router-dom";

const PaymentHistory = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-history">
      <img
        src={goBackIcon}
        alt=""
        onClick={() => navigate("/home/profile/my-payments")}
      />
      <SettingsSectionTitle label={"Historial de Pagos"} />
      <PaymentHistoryItem
        pay={"Compra de Suscripción"}
        paymentDate={"15/07/2024"}
      />
      <PaymentHistoryItem
        pay={"Compra de Suscripción"}
        paymentDate={"30/06/2024"}
      />
      <PaymentHistoryItem
        pay={"Compra de Suscripción"}
        paymentDate={"15/06/2024"}
      />
    </div>
  );
};

export { PaymentHistory };
