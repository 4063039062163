import React from "react";
import "./view-title-and-back-button-styles.css";
import leftArrowIcon from "../../assets/icons/go-back.svg";
import { useNavigate } from "react-router-dom";

const ViewTitleAndBackButton = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="back-button-and-view-title">
      <img
        src={leftArrowIcon}
        alt="left arrow icon"
        onClick={() => navigate(-1)}
      />
      <label>{title}</label>
      <div></div>
    </div>
  );
};

export { ViewTitleAndBackButton };
