import React, { useState } from 'react';
import './forgot-password.css';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const navigation = useNavigate();
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Email:', email);
    };

    return (
        <div className="forgot-password-form-container">
            <h2>Recuperar Contraseña</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-input"
                        required
                    />
                </div>
                <button type="submit" className="form-button">Enviar Enlace</button>
            </form>
            <p className="login-link">
                <span onClick={() => { navigation('/login') }}>Volver a Iniciar Sesión</span>
            </p>
        </div>
    );
};

export { ForgotPassword };
