// features/enterprise/enterpriseSlice.js
// Manejo del estado de la empresa
import { createSlice } from '@reduxjs/toolkit';
import { enterpriseApiSlice } from './enterpriseApiSlice';

const initialState = {
  enterprises: [], // Cambiado de empresa a enterprises para mantener consistencia
  main: null,
  current: null,
  defaultRole: null,
  status: 'idle',
  error: null,
};

export const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState,
  reducers: {
    setMainEnterprise: (state, action) => {
      state.main = action.payload;
    },
    setCurrentEnterprise: (state, action) => {
      state.current = action.payload;
    },
    setDefaultEnterpriseRole: (state, action) => {
      state.defaultRole = action.payload;
    },
    resetEnterprise: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Create enterprise matchers
      .addMatcher(
        enterpriseApiSlice.endpoints.createEnterprise.matchPending,
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        enterpriseApiSlice.endpoints.createEnterprise.matchFulfilled,
        (state, action) => {
          state.status = 'succeeded';
          state.enterprises.push(action.payload);
        }
      )
      .addMatcher(
        enterpriseApiSlice.endpoints.createEnterprise.matchRejected,
        (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        }
      )
      // Get enterprise matchers
      .addMatcher(
        enterpriseApiSlice.endpoints.getEnterprise.matchPending,
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        enterpriseApiSlice.endpoints.getEnterprise.matchFulfilled,
        (state, action) => {
          state.status = 'succeeded';
          state.enterprises = action.payload;
        }
      )
      .addMatcher(
        enterpriseApiSlice.endpoints.getEnterprise.matchRejected,
        (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        }
      );
  },
});

export const {
  setMainEnterprise,
  setCurrentEnterprise,
  setDefaultEnterpriseRole,
  resetEnterprise,
} = enterpriseSlice.actions;

// Selectores para acceder al estado
export const selectAllEnterprises = (state) => state.enterprise.enterprises;
export const selectMainEnterprise = (state) => state.enterprise.main;
export const selectCurrentEnterprise = (state) => state.enterprise.current;
export const selectDefaultEnterpriseRole = (state) => state.enterprise.defaultRole;
export const selectEnterpriseStatus = (state) => state.enterprise.status;
export const selectEnterpriseError = (state) => state.enterprise.error;

export default enterpriseSlice.reducer;
