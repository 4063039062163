import React from "react";
import "./billing-information.css";
import goBackIcon from "../../../../../assets/icons/go-back.svg";
import attachIcon from "../../../../../assets/icons/attach.svg";
import { SettingsSectionTitle } from "../../../../../components/settings-section-title/SettingsSectionTitle";
import { MobileSubscritptionInput } from "../../../../../components/mobile-subscription-input/MobileSubscriptionInput";
import { useNavigate } from "react-router-dom";

const BillingInformation = () => {
  const navigate = useNavigate();
  return (
    <div className="billing-information">
      <img
        src={goBackIcon}
        alt=""
        onClick={() => navigate("/home/profile/my-payments")}
      />
      <SettingsSectionTitle label={"Información para Facturación"} />
      <MobileSubscritptionInput label={"Nombre de la Empresa"} />
      <MobileSubscritptionInput label={"NIF"} />
      <MobileSubscritptionInput label={"RFC"} />
      <MobileSubscritptionInput label={"Dirección de la Empresa"} />
      <MobileSubscritptionInput label={"Ciudad"} />
      <MobileSubscritptionInput label={"Estado"} />
      <MobileSubscritptionInput label={"País"} />
      <MobileSubscritptionInput label={"Código Postal"} />
      <MobileSubscritptionInput label={"Teléfono de Contacto"} />
      <MobileSubscritptionInput label={"Correo Electrónico"} />
      <section className="attach-section">
        <label>Documentación</label>
        <button>
          <img src={attachIcon} alt="" />
          <label>Adjuntar Archivo</label>
        </button>
      </section>
    </div>
  );
};

export { BillingInformation };
