import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../register-employee/register-employee.css'

const EditionEmployee = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        nombres: '',
        apellidos: '',
        correo: '',
        telefono: '',
        direccion: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add validation here if needed
        console.log(formData);
    };

    const handleCancel = () => {
        navigate('/employees');
    };

    return (
        <>
            <div className="container-register-employee">
                <div className="employee-photo-register-container">
                    <div></div>
                    <label>Id de Empleado</label>
                    <span>0001</span>
                </div>
                <div className="employee-registration-form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group-employee-registration">
                            <label htmlFor="nombres">Nombres</label>
                            <input
                                type="text"
                                id="nombres"
                                name="nombres"
                                value={formData.nombres}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group-employee-registration">
                            <label htmlFor="apellidos">Apellidos</label>
                            <input
                                type="text"
                                id="apellidos"
                                name="apellidos"
                                value={formData.apellidos}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group-employee-registration">
                            <label htmlFor="correo">Correo</label>
                            <input
                                type="email"
                                id="correo"
                                name="correo"
                                value={formData.correo}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group-employee-registration">
                            <label htmlFor="telefono">Teléfono</label>
                            <input
                                type="tel"
                                id="telefono"
                                name="telefono"
                                value={formData.telefono}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group-employee-registration">
                            <label htmlFor="direccion">Dirección</label>
                            <input
                                type="text"
                                id="direccion"
                                name="direccion"
                                value={formData.direccion}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="employee-registration-button-container">
                            <button type="button" onClick={handleCancel}>Cancelar Cambios</button>
                            <button type="submit">Guardar Empleado</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { EditionEmployee }