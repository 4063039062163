import React from "react";
import './paper-bin.css'

const PaperBin = () => {
    <>
        <div>
            Papelera por que no se ve
        </div>
    </>
}

export { PaperBin }