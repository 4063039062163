import React from "react";
import "./item-show-data.css";

const ItemShowData = ({label, value}) => {
  return (
    <div className="item-show-data">
      <label>{label}</label>
      <label>{value}</label>
    </div>
  );
};

export { ItemShowData };
