// employeeApiSlice.js
import { apiSlice } from '../../api/apiSlice';

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    RegisterEmployee: builder.mutation({
      query: (body) => ({
        url: '/employee/register',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Employee'],
    }),
  })
});

export const {
  useRegisterEmployeeMutation
} = employeeApiSlice;
