// store.js
// Configuración del store de Redux
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { apiSlice } from '../api/apiSlice';
import authReducer from '../features/auth/authSlice';
import enterpriseReducer from '../features/enterprise/enterpriseSlice';
import employeeReducer from '../features/employee/employeeSlice';

// Configuración de persistencia
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['accessToken'],
};

const enterprisePersistConfig = {
  key: 'enterprise',
  storage,
};

const employeePersistConfig = {
  key: 'employee',
  storage,
};

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistReducer(authPersistConfig, authReducer),
    enterprise: persistReducer(enterprisePersistConfig, enterpriseReducer),
    employee: persistReducer(employeePersistConfig, employeeReducer)
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

const persistor = persistStore(store);

export { store, persistor };
