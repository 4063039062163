import React from "react";
import "./browser-label.css";
import rigth from "../../assets/icons/right.svg";
import { useNavigate } from "react-router-dom";

const BrowserLabel = ({ label, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <div className="browser-label" onClick={() => navigate(navigateTo)}>
      <label>{label}</label>
      <img src={rigth} alt="" />
    </div>
  );
};

export { BrowserLabel };
