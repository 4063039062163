import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import {
  useRoutes,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./features/store";
import App from "./App";
import RequireAuth from "./features/auth/RequireAuth";
import { Login } from "./views/account/login/Login";
import { SignUp } from "./views/account/sign-up/SignUp";
import { ForgotPassword } from "./views/account/forgot-password/ForgotPassword";
import { Employees } from "./views/employees/Employees";
import { RegisterEmployee } from "./views/employees/register-employee/RegisterEmployee";
import { EditionEmployee } from "./views/employees/edition-employee/EditionEmployee";
import { EmployeeForm } from "./views/employees/employee-form/EmployeeForm";
import { Home } from "./views/home/Home";
import { Plans } from "./views/home/plans/Plans";
import { Profile } from "./views/home/profile/Profile";
import { LandingPage } from "./views/landing-page/LandingPage";
import { PaperBin } from "./views/paper-bin/PaperBin";
import { Payments } from "./views/payments/Payments";
import { Subscriptions } from "./views/subscription/Subscription";
import { CompanyLocation } from "./views/home/profile/company-location/CompanyLocation";
import { LoginAndSecurity } from "./views/home/profile/login-and-security/LoginAndSecurity";
import { AdvancedConfiguration } from "./views/home/profile/advanced-configuration/AdvancedConfiguration";
import { MyPayments } from "./views/home/profile/my-payments/MyPayments";
import { BillingInformation } from "./views/home/profile/my-payments/billing-information/BillingInformation";
import { PaymentHistory } from "./views/home/profile/my-payments/payment-history/PaymentHistory";
import { PurchaseDetail } from "./views/subscription/purchase-detail/PurchaseDetail";
import { PaymentMethod } from "./views/subscription/payment-method/PaymentMethod";
import { SubscriptionPaymentHistory } from "./views/subscription/subscription-payment-history/SubscriptioPaymentHistory";

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <LandingPage /> },
    { path: "/home", element: <Home /> },
    { path: "/home/profile", element: <Profile /> },
    { path: "/home/profile/company-location", element: <CompanyLocation /> },
    { path: "/home/profile/login-and-security", element: <LoginAndSecurity /> },
    {
      path: "/home/profile/advanced-configuration",
      element: <AdvancedConfiguration />,
    },
    {
      path: "/home/profile/billing-information",
      element: <BillingInformation />,
    },
    { path: "/home/profile/payment-history", element: <PaymentHistory /> },
    { path: "/home/profile/my-payments", element: <MyPayments /> },
    { path: "/home/plans", element: <Plans /> },
    { path: "/home/purchase-detail", element: <PurchaseDetail /> },
    { path: "/home/payment-method", element: <PaymentMethod /> },
    { path: "/home/new-payment-method", element: <Subscriptions /> },
    {
      path: "/home/subscription-payment-history",
      element: <SubscriptionPaymentHistory />,
    },
    { path: "/employees", element: <Employees /> },
    { path: "/employees/register-employee", element: <RegisterEmployee /> },
    { path: "/employees/edition-employee", element: <EditionEmployee /> },
    { path: "/employees/manager-employee", element: <EmployeeForm /> },
    { path: "/subscriptions", element: <Subscriptions /> },
    { path: "/paper-bin", element: <PaperBin /> },
    { path: "/payments", element: <Payments /> },
    { path: "/login", element: <Login /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    {
      element: <RequireAuth />,
      children: [
        { path: "/home", element: <Home /> },
        { path: "/home", element: <Home /> },
        { path: "/home/profile", element: <Profile /> },
        {
          path: "/home/profile/company-location",
          element: <CompanyLocation />,
        },
        {
          path: "/home/profile/login-and-security",
          element: <LoginAndSecurity />,
        },
        {
          path: "/home/profile/advanced-configuration",
          element: <AdvancedConfiguration />,
        },
        {
          path: "/home/profile/billing-information",
          element: <BillingInformation />,
        },
        { path: "/home/profile/payment-history", element: <PaymentHistory /> },
        { path: "/home/profile/my-payments", element: <MyPayments /> },
        { path: "/home/plans", element: <Plans /> },
        { path: "/employees", element: <Employees /> },
        { path: "/employees/register-employee", element: <RegisterEmployee /> },
        { path: "/employees/edition-employee", element: <EditionEmployee /> },
        { path: "/employees/manager-employee", element: <EmployeeForm /> },
        { path: "/subscriptions", element: <Subscriptions /> },
        { path: "/paper-bin", element: <PaperBin /> },
        { path: "/payments", element: <Payments /> },
      ],
    },
    { path: "*", element: <Navigate to="/login" replace /> }, // Redirect any unknown routes to login
  ]);
  return routes;
};

const Main = () => {
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const location = useLocation();
  const isAuthRoute = ["/login", "/sign-up", "/forgot-password", "/"].includes(
    location.pathname
  );

  return (
    <>
      <div
        className={` ${isAuthRoute
          ? "full-screen-view"
          : `view-main ${sideBarVisible ? "expanded" : "collapsed"}`
          } `}
      >
        <AppRoutes />
      </div>

      <App
        sideBarVisible={sideBarVisible}
        setSideBarVisible={setSideBarVisible}
      />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  </React.StrictMode >
);
