import React from "react";
import './footer.css';
import texts from "../../texts.json"

const Footer = () => {
  return (
    <>
      <footer className="plataform-footer">
        {texts.components.footer}
      </footer>
    </>
  )
}

export default Footer;

