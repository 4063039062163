import React, { useCallback, useState } from "react";
import "./purchase-detail-styles.css";
import rightArrowIcon from "../../../assets/icons/right.svg";
import cardIcon from "../../../assets/icons/wallet-outline.svg";
import greenCheckIcon from "../../../assets/icons/green-check-icon.svg";
import rightIcon from "../../../assets/icons/right.svg";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { MobileActionButton } from "../../../components/mobile-action-button/MobileActionButton";
import { ViewTitleAndBackButton } from "../../../components/view-title-and-back-button/ViewTitleAndBackButton";

const PurchaseDetail = () => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const hadleCloseModal = () => {
    closeModal();
    navigate("/home/subscription-payment-history");
  };
  const width = windowWidth - 95;
  return (
    <>
      <div className="view-payment-detail">
        <div className="plans-text">
          <label>Nuestros Planes</label>
          <p>Encuentra el plan perfecto para ti y para tu empresa</p>
        </div>
        <div
          className="detail-plan-to-pay"
          style={{ width: windowWidth >= 1300 ? "70%" : "100%" }}
        >
          <div className="plan-to-pay">
            <h4>Plan Básico</h4>
            <label>Incluye</label>
            <ul>
              <li>Hasta 50 Empleados</li>
              <li>
                Funciones Principales
                <ul>
                  <li>Registro de empleados</li>
                  <li>Planificación de viajes</li>
                  <li>Reserva de asientos</li>
                </ul>
              </li>
              <li>Soporte Básico</li>
              <li>Reporte Mensual Básico</li>
            </ul>
          </div>
          <div className="payment-details">
            <div className="detail-and-price">
              <label>Detalles</label>
              <div className="cost-and-plan-name">
                <label>1x NOMBRE Plan Básico Anual</label>
                <label>$1,440.00 MXN</label>
              </div>
              <div className="monthly-payment">
                <span>12 pagos de $120.00 mensuales</span>
              </div>
            </div>
            <div className="payment-method">
              <label onClick={() => setSelectedPaymentMethod(true)}>
                Método de Pago
              </label>
              <button onClick={() => navigate("/home/payment-method")}>
                {selectedPaymentMethod ? (
                  <div className="payment-card-data">
                    <img src={cardIcon} alt="" />
                    <div>
                      <label>
                        <strong>MasterCard |</strong> Nombre Apellido TDC
                      </label>
                      <div className="card-number-and-expiration-date">
                        <span>.... 0123</span>
                        <span>03/29</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <label>Seleccionar Método de Pago </label>
                )}
                <img src={rightArrowIcon} alt="right arrow icon" />
              </button>
              <div className="continue-with-payment">
                <button onClick={() => openModal()}>Continuar con Pago</button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={hadleCloseModal}
          className="modalContent"
          overlayClassName="modalOverlay"
          contentLabel="Delete Employee Modal"
        >
          <img src={greenCheckIcon} alt="checkout" />
          <h3>Tu compra ha sido registrada</h3>
          <p>Bienvenido a NOMBRE BÁSICO</p>
        </Modal>
      </div>
      <div className="mobile-view-purchase">
        <ViewTitleAndBackButton title={"Completa tu pago"} />
        <div className="plan-card-for-payment-detail">
          <div className="name-and-price-of-the-plan">
            <label>Plan Básico</label>
            <div className="plan-price-per-month">
              <label>$120</label>
              <span>/mes</span>
            </div>
          </div>
          <div className="subscription-plan-details">
            <label>Incluye</label>
            <ul>
              <li>Hasta 50 Empleados</li>
              <li>
                Funciones Principales
                <ul>
                  <li>Registro de empleados</li>
                  <li>Planificación de viajes</li>
                  <li>Reserva de asientos</li>
                </ul>
              </li>
              <li>Soporte Básico</li>
              <li>Reporte Mensual Básico</li>
            </ul>
          </div>
        </div>
        <div className="payment-method-section-in-subscription">
          <label onClick={() => setSelectedPaymentMethod(true)}>
            Método de Pago
          </label>
          {selectedPaymentMethod ? (
            <div
              className="mobile-card-payment-method"
              style={{ width: width }}
            >
              <div className="card-icon-containerfor-mobile-payment-method">
                <img src={cardIcon} alt="cardIcon" />
              </div>
              <div className="mobile-payment-method-information">
                <label>MasterCard</label>
                <div className="payment-card-holder">
                  <label>Nombre Apellido TDC</label>
                  <img src={rightIcon} alt="right arrow" />
                </div>
                <div className="mobile-payment-method-card-number-and-expiration-date">
                  <label>.... 0123</label>
                  <label>03/29</label>
                </div>
              </div>
            </div>
          ) : (
            <button
              className="payment-method-selection-button"
              onClick={() => navigate("/home/payment-method")}
            >
              <label>Seleccionar Método de Pago</label>
              <img src={rightArrowIcon} alt="right arrow icon" />
            </button>
          )}
        </div>
        <div className="container-for-the-continue-payment-button">
          <MobileActionButton
            text={"Continuar con Pago"}
            onClick={() => openModal()}
          />
        </div>
      </div>
    </>
  );
};

export { PurchaseDetail };
