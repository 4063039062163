import React from "react";
import "./login-and-security.css";
import goBackIcon from "../../../../assets/icons/go-back.svg";
import { SettingsSectionTitle } from "../../../../components/settings-section-title/SettingsSectionTitle";
import { SettingsFunction } from "../../../../components/settings-function/SettingsFunction";
import { useNavigate } from "react-router-dom";

const LoginAndSecurity = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="login-and-security-movil">
        <img
          src={goBackIcon}
          alt=""
          onClick={() => navigate("/home/profile")}
        />
        <SettingsSectionTitle label={"Inicio de Sesión y Seguridad"} />
        <div className="list-settings-functions">
          <SettingsFunction
            title={"Verificación de dos pasos"}
            description={
              "Protege tu cuenta con un nivel adicional de seguridad utilizando la verificación en dos pasos."
            }
            buttonText={"Activar"}
          />
          <SettingsFunction
            title={"Reestablecer contraseña"}
            description={"Olvidé mi contraseña, pasos para reestablecerla"}
            buttonText={"Reestablecer Contraseña"}
          />
          <SettingsFunction
            title={"Cerrar sesión en todos los dispositivos"}
            description={
              "¿Ha iniciado sesión en un dispositivo compartido pero olvidó cerrar sesión? Finalice todas las sesiones cerrando sesión en todos los dispositivos."
            }
            buttonText={"Cerrar Sesión"}
          />
        </div>
      </div>
    </>
  );
};

export { LoginAndSecurity };
