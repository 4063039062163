import React from "react";
import "./subscription.css";
import { MobileSubscritptionInput } from "../../components/mobile-subscription-input/MobileSubscriptionInput";
import { MobileActionButton } from "../../components/mobile-action-button/MobileActionButton";
import { DesktopSubsInput } from "../../components/desktop-subs-input/DesktopSubsInput";
import { FormButtonsDesktop } from "../../components/form-buttons-desktop/FormButtonsDesktop";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useNavigate } from "react-router-dom";
import { ViewTitleAndBackButton } from "../../components/view-title-and-back-button/ViewTitleAndBackButton";

const Subscriptions = () => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="deskSubs-subsGeneral"
        style={{ width: windowWidth >= 1200 ? "70%" : "100%" }}
      >
        <h4>Método de Pago</h4>
        <section className="gridSelect-pay">
          <DesktopSubsInput label={"Seleccionar Método de Pago"} />
        </section>
        <section className="cardInformation">
          <DesktopSubsInput label={"Titular"} />
          <DesktopSubsInput
            label={"Fecha de Expiración"}
            style={{ gridColumn: "span 5" }}
          />
          <DesktopSubsInput label={"Número de Tarjeta"} />
          <DesktopSubsInput label={"CVV"} style={{ gridColumn: "span 5" }} />
        </section>
        <section className="buttonsPay">
          <FormButtonsDesktop
            textDark={"Cancelar"}
            textGreen={"Siguiente"}
            onClickDark={() => navigate("/home/payment-method")}
            onClickGreen={() => navigate("/home/payment-method")}
          />
        </section>
      </div>
      <div className="containerMainSubs">
        <section className="form-subs">
          <div className="mobile-payment-method-title-container">
            <ViewTitleAndBackButton title={"Método de Pago"} />
          </div>
          <MobileSubscritptionInput label={"Titular"} />
          <MobileSubscritptionInput label={"Número de Tarjeta"} />
          <MobileSubscritptionInput label={"Fecha de Expiración"} />
          <MobileSubscritptionInput label={"CVV"} />
        </section>
        <section>
          <MobileActionButton text={"Cancelar"} cancel={true} />
          <MobileActionButton
            text={"Siguiente"}
            onClick={() => navigate("/home/payment-method")}
          />
        </section>
      </div>
    </>
  );
};

export { Subscriptions };
