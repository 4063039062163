import React from "react";
import "./subscription-payment-history-styles.css";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { ViewTitleAndBackButton } from "../../../components/view-title-and-back-button/ViewTitleAndBackButton";
import { ItemSubscriptionPurchase } from "../../../components/item-subscription-purchase/ItemSubscriptionPurchase";

const SubscriptionPaymentHistory = () => {
  const windowWidth = useWindowWidth();
  const data = [
    {
      fecha: "31/08/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$1,440.00",
      factura: "Descargar",
    },
    {
      fecha: "15/08/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$1,440.00",
      factura: "Descargar",
    },
    {
      fecha: "31/07/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$1,440.00",
      factura: "Descargar",
    },
    {
      fecha: "15/07/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$1,440.00",
      factura: "Descargar",
    },
  ];
  return (
    <>
      <div
        className="view-subscription-payment-history"
        style={{ width: windowWidth >= 1200 ? "70%" : "100%" }}
      >
        <div className="payment-history-view-title">Historial de pagos</div>
        <table
          className="payment-history-table"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            textAlign: "center",
          }}
        >
          <thead>
            <tr>
              <th>Fecha</th>
              <th style={{ textAlign: "left", paddingLeft: 10 }}>Concepto</th>
              <th>Cantidad</th>
              <th>Factura</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="table-cells">{item.fecha}</td>
                <td className="table-cells" style={{ textAlign: "left" }}>
                  {item.concepto}
                </td>
                <td className="table-cells">{item.cantidad}</td>
                <td className="table-cells">
                  <button className="paytable-button-download">
                    {item.factura}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mobile-payment-history-view">
        <ViewTitleAndBackButton title={"Historial de Pagos"} />
        <div className="mobile-payment-list">
          <ItemSubscriptionPurchase />
          <ItemSubscriptionPurchase />
          <ItemSubscriptionPurchase />
        </div>
      </div>
    </>
  );
};

export { SubscriptionPaymentHistory };
