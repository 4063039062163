import React from "react";
import "./my-payments.css";
import goBackIcon from "../../../../assets/icons/go-back.svg";
import { useNavigate } from "react-router-dom";
import { SettingsSectionTitle } from "../../../../components/settings-section-title/SettingsSectionTitle";
import { SettingsFunction } from "../../../../components/settings-function/SettingsFunction";
import { BrowserLabel } from "../../../../components/browser-label/BrowserLabel";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { GreenBorderButton } from "../../../../components/green-border-button/GreenBorderButton";
import downloadIcon from "../../../../assets/icons/download.svg";

const MyPayments = () => {
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const data = [
    {
      fecha: "31/07/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$350.00",
      factura: "Solicitar",
    },
    {
      fecha: "31/07/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$350.00",
      factura: "Solicitar",
    },
    {
      fecha: "31/07/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$350.00",
      factura: "Solicitar",
    },
    {
      fecha: "31/07/2024",
      concepto: "Nombre Nombre Apellido Apellido",
      cantidad: "$350.00",
      factura: "Solicitar",
    },
  ];
  return (
    <div className="my-payments">
      <img src={goBackIcon} alt="" onClick={() => navigate("/home/profile")} />
      <SettingsSectionTitle label={"Mis Pagos"} />
      <SettingsFunction
        description={
          "Administra tu información de pago para compras de pago único. Para administrar la información de tu suscripción mensual, dirígete a Mi Suscripción."
        }
        payment={true}
      />
      {windowWidth <= 480 ? (
        <BrowserLabel
          label={"Información para Facturación"}
          navigateTo={"/home/profile/billing-information"}
        />
      ) : (
        <div className="billing-section-container">
          <label className="payment-history-title">
            Información para Facturación
          </label>
          <GreenBorderButton text={"Ir"} border={true} />
        </div>
      )}
      {windowWidth <= 480 ? (
        <BrowserLabel
          label={"Historial de Pagos"}
          navigateTo={"/home/profile/payment-history"}
        />
      ) : (
        <label className="payment-history-title">Historial de Pagos</label>
      )}
      {windowWidth > 480 && (
        <table
          className="payment-history-table"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th>Fecha</th>
              <th style={{ textAlign: "left", paddingLeft: 10 }}>Concepto</th>
              <th>Cantidad</th>
              <th>Factura</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="table-cells">{item.fecha}</td>
                <td className="table-cells" style={{ textAlign: "left" }}>
                  {item.concepto}
                </td>
                <td className="table-cells">{item.cantidad}</td>
                <td className="table-cells">
                  <button className="paytable-button">{item.factura}</button>
                </td>
                <td className="table-cells">
                  <img src={downloadIcon} alt="" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export { MyPayments };
