import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import language from "../../../assets/icons/language.svg";
import { useLoginMutation } from "../../../features/auth/authApiSlice";
import { setAccessToken } from "../../../features/auth/authSlice";
import { setEmployee } from "../../../features/employee/employeeSlice";
import logo from "../../../assets/images/logo96px.png";

const Login = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({
        username: credentials.emailOrPhone,
        password: credentials.password,
      }).unwrap();
      dispatch(setAccessToken(userData.token));
      dispatch(setEmployee(userData.user.idEmployee));
      navigation("/home");
    } catch (err) {
      if (err.status === 401) {
        setError("Invalid credentials. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const [credentials, setCredentials] = useState({
    emailOrPhone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  return (
    <>
      <div className="title-form-login account-header">
        <img style={{ maxHeight: "42vh" }} src={logo} alt="amvi" />
      </div>
      <div className="login-form-container">
        <h2>Iniciar Sesión</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group-login">
            <label htmlFor="email">Correo o Teléfono</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              required
            />
          </div>
          <div className="form-group-login">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              required
            />
            <p className="forgot-password-link">
              <span
                onClick={() => {
                  navigation("/forgot-password");
                }}
              >
                Restablecer contraseña
              </span>
            </p>
          </div>
          <button type="submit" className="form-button">
            Iniciar Sesión
          </button>
        </form>
      </div>
      <div className="login-desktop">
        <div className="header-login-desktop">
          <div className="brand-login-desktop">
            <img style={{ maxWidth: "4vw" }} src={logo} alt="amvi" />
          </div>
          <div className="language-login-desktop">
            <img src={language} alt="" />
            <label>Es</label>
          </div>
        </div>
        <div className="login-image-desktop">
          <div className="brand-form-desktop">
            <img style={{ maxHeight: "34vh" }} src={logo} alt="amvi" />
          </div>
          <form onSubmit={handleSubmit} className="form-desktop">
            <h2>Iniciar Sesión</h2>

            <div className="formGroup-login-desktop">
              <label htmlFor="emailOrPhone">Correo o Teléfono</label>
              <input
                type="text"
                id="emailOrPhone"
                name="emailOrPhone"
                value={credentials.emailOrPhone}
                onChange={handleChange}
                required
              />
            </div>

            <div className="formGroup-login-desktop">
              <label htmlFor="password">Contraseña</label>
              <input
                type="password"
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="forgot-password-desktop">
              <a href="/reset-password">Reestablecer contraseña</a>
            </div>

            <button type="submit" className="form-button" disabled={isLoading}>
              {isLoading ? "Iniciando sesión..." : "Iniciar Sesión"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export { Login };
