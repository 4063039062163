import React, { useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./employee-form.css";
import goBackIcon from "../../../assets/icons/go-back.svg";
import trashIcon from "../../../assets/icons/trash-outline.svg";
import papeleraMovilImg from "../../../assets/images/papelera.png";
import shareIcon from "../../../assets/icons/share.svg";
import { useRegisterEmployeeMutation } from '../../../features/employee/employeeApiSlice';

const EmployeeForm = ({ defaultMode = "mostrar" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [registerEmployee, { isLoading }] = useRegisterEmployeeMutation();

  const mode = useMemo(
    () => location.state?.mode || defaultMode,
    [location.state, defaultMode]
  );
  const [isEditable, setIsEditable] = useState(mode !== "mostrar");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');

  // Obtener empleado del estado de la ubicación si existe
  const employee = location.state?.employee;

  const initialFormData = useMemo(
    () => ({
      estado: mode === "crear" ? "Activo" : employee?.estado || "",
      correo: mode === "crear" ? "" : employee?.correo || "",
      telefono: mode === "crear" ? "" : employee?.telefono || "",
      viaje: mode === "crear" ? "" : employee?.viaje || "",
      nombre: mode === "crear" ? "" : employee?.nombre || "",
    }),
    [mode, employee]
  );

  const [formData, setFormData] = useState(initialFormData);

  const handleNavigation = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }, []);

  const handleEdit = useCallback(() => setIsEditable(true), []);
  
  const handleCancel = useCallback(() => {
    setIsEditable(false);
    setFormData(initialFormData);
  }, [initialFormData]);

  const handleSubmit = useCallback(async () => {
    try {
      const employeeData = {
        name: formData.nombre,
        email: formData.correo,
        phone: formData.telefono,
        status: formData.estado,
      };

      await registerEmployee(employeeData).unwrap();
      setIsEditable(false);
      navigate('/employees');
    } catch (err) {
      console.error('Failed to create employee:', err);
      setError(err.data?.message || 'Error al crear empleado');
    }
  }, [formData, registerEmployee, navigate]);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <section className="main-container-EmployeeForm">
        <div className="back-and-trash-EmployeeForm">
          <img
            src={goBackIcon}
            alt="back"
            onClick={() => handleNavigation("/employees")}
          />
          {!isEditable && mode !== "crear" && (
            <img src={trashIcon} alt="trash" onClick={openModal} />
          )}
          {mode === "crear" && <img src={shareIcon} alt="share" />}
        </div>
        
        {error && <div className="error-message">{error}</div>}

        <div className="info-EmployeeForm">
          <div style={{ borderColor: isEditable ? "#fff" : "" }}></div>
          {mode === "crear" ? (
            <input
              type="text"
              placeholder="Nombre de Empleado"
              value={formData.nombre}
              onChange={handleInputChange}
              name="nombre"
              disabled={!isEditable}
            />
          ) : (
            <>
              <h4>{employee?.nombre}</h4>
              <p>{employee?.id}</p>
            </>
          )}
        </div>
        
        <form className={`form-EmployeeForm ${isEditable ? "form-edit-employee" : ""}`}>
          <div>
            <label>Estado</label>
            <select
              name="estado"
              value={formData.estado}
              onChange={handleInputChange}
              disabled={!isEditable}
              className={!isEditable ? "select-no-arrow" : ""}
            >
              <option value="Activo">Activo</option>
              <option value="Pendiente de Aprobacion">Pendiente de Aprobación</option>
              <option value="Suspendido">Suspendido</option>
            </select>
          </div>

          <div>
            <label>Correo</label>
            <input
              type="email"
              name="correo"
              value={formData.correo}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </div>

          <div>
            <label>Teléfono</label>
            <input
              type="tel"
              name="telefono"
              value={formData.telefono}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </div>

          <div>
            <label>Viaje</label>
            <textarea
              name="viaje"
              value={formData.viaje}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </div>

          <div style={{ marginTop: isEditable ? "72px" : "134px" }}>
            {isEditable ? (
              <>
                <button
                  className="cancel-button-EmployeeForm"
                  type="button"
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancelar
                </button>
                <button
                  className="action-button-EmployeeForm"
                  type="button"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? "Guardando..." : mode === "crear" ? "Crear" : "Guardar"}
                </button>
              </>
            ) : (
              <button
                className="action-button-EmployeeForm"
                type="button"
                onClick={handleEdit}
              >
                Editar
              </button>
            )}
          </div>
        </form>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modalContent"
        overlayClassName="modalOverlay"
        contentLabel="Confirmation Modal"
      >
        <img src={papeleraMovilImg} alt="Papelera" />
        <p>¿Estás seguro de mover</p>
        <h3>
          {employee?.nombre} {employee?.apellido}
        </h3>
        <p>a la papelera?</p>
        <div>
          <button onClick={closeModal}>Aceptar</button>
          <button onClick={closeModal}>Cancelar</button>
        </div>
      </Modal>
    </>
  );
};

export { EmployeeForm };
