import React, { useState } from "react";
import "./profile.css";
import camera from "../../../assets/icons/camera-outline.svg";
import goBackIcon from "../../../assets/icons/go-back.svg";
import editIcon from "../../../assets/icons/create-outline.svg";
import { MobileActionButton } from "../../../components/mobile-action-button/MobileActionButton";
import { MobileSubscritptionInput } from "../../../components/mobile-subscription-input/MobileSubscriptionInput";
import { BrowserLabel } from "../../../components/browser-label/BrowserLabel";
import { useNavigate } from "react-router-dom";
import { GreenBorderButton } from "../../../components/green-border-button/GreenBorderButton";
import cameraIcon from "../../../assets/icons/camera-outline.svg";
import { ItemShowData } from "../../../components/item-show-data/ItemShowData";
import { useGetEnterpriseProfileQuery } from "../../../features/enterprise/enterpriseApiSlice";

const Profile = () => {
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const { data: enterpriseData, isLoading, error } = useGetEnterpriseProfileQuery();

  // Función para formatear la dirección completa
  const getFullAddress = () => {
    if (!enterpriseData?.data?.contact) return "Sin dirección";

    const contact = enterpriseData.data.contact;
    const addressParts = [
      contact.street,
      contact.buildingNumber,
      contact.city,
      contact.state,
      contact.postalCode,
      contact.country
    ].filter(Boolean);

    return addressParts.length > 0 ? addressParts.join(", ") : "Sin dirección";
  };

  if (isLoading) return <div>Cargando...</div>;
  if (error) return <div>Error al cargar los datos</div>;

  return (
    <>
      <div className="deskSubs-general">
        <div className="photo-button-container">
          <div className="blue-camera-icon-container">
            <img src={cameraIcon} alt="" />
          </div>
          <div>
            <GreenBorderButton text={"Cambiar foto"} border={true} />
            <GreenBorderButton text={"Quitar foto"} border={false} />
          </div>
        </div>
        <div className="company-data-list-container">
          <div className="data-container-button">
            <ItemShowData label={"Nombre"} value={enterpriseData?.data?.name} />
            <GreenBorderButton text={"Editar"} border={true} />
          </div>
          <div className="data-container-button">
            <ItemShowData
              label={"Correo Electrónico"}
              value={enterpriseData?.data?.email}
            />
            <GreenBorderButton text={"Editar"} border={true} />
          </div>
          <div className="data-container-button">
            <ItemShowData
              label={"Dirección de la Empresa"}
              value={getFullAddress()}
            />
            <GreenBorderButton text={"Editar"} border={true} />
          </div>
          <div className="data-container-button">
            <ItemShowData label={"Idioma"} value={enterpriseData?.data?.lenguage} />
            <GreenBorderButton text={"Editar"} border={true} />
          </div>
        </div>
        <div className="advanced-options-container">
          <BrowserLabel
            label={"Inicio de Sesión y Seguridad"}
            navigateTo={"/home/profile/login-and-security"}
          />
          <div className="container-with-grey-edges">
            <BrowserLabel
              label={"Configuración Avanzada"}
              navigateTo={"/home/profile/advanced-configuration"}
            />
          </div>
          <BrowserLabel
            label={"Mis Pagos"}
            navigateTo={"/home/profile/my-payments"}
          />
        </div>
      </div>
      <div className="general-container-profile">
        {!edit && (
          <>
            <div className="navigation-option-profile">
              <img src={goBackIcon} alt="" onClick={() => navigate("/home")} />
              <img src={editIcon} alt="" onClick={() => setEdit(true)} />
            </div>
            <section className="profile-general-information">
              <div className="profile-picture">
                <div>
                  <img src={camera} alt="" />
                </div>
              </div>
            </section>
            <ItemShowData label={"Nombre"} value={enterpriseData?.data?.name} />
            <ItemShowData
              label={"Correo Electrónico"}
              value={enterpriseData?.data?.email}
            />
            <ItemShowData
              label={"Dirección de la Empresa"}
              value={getFullAddress()}
            />
            <ItemShowData label={"Idioma"} value={enterpriseData?.data?.lenguage} />
            <div className="browser-tag-container">
              <BrowserLabel
                label={"Inicio de Sesión y Seguridad"}
                navigateTo={"/home/profile/login-and-security"}
              />
              <BrowserLabel
                label={"Configuración Avanzada"}
                navigateTo={"/home/profile/advanced-configuration"}
              />
              <BrowserLabel
                label={"Mis Pagos"}
                navigateTo={"/home/profile/my-payments"}
              />
            </div>
          </>
        )}
        {edit && (
          <>
            <section className="profile-general-information">
              <div className="profile-picture">
                <div></div>
              </div>
            </section>
            <section className="form-profile">
              <MobileSubscritptionInput label={"Nombre de la Empresa"} />
              <MobileSubscritptionInput label={"Direccion"} />
              <MobileSubscritptionInput label={"RFC"} />
              <MobileSubscritptionInput label={"Idioma"} />
            </section>
            <section>
              <MobileActionButton text={"Cancelar"} cancel={true} />
              <MobileActionButton
                text={"Guardar"}
                onClick={() => setEdit(false)}
              />
            </section>
          </>
        )}
      </div>
    </>
  );
};

export { Profile };
