import React, { useEffect, useMemo, useState } from "react";
import "./landing-page.css";
import { useNavigate } from "react-router-dom";
import carpooling from "../../assets/images/Carpool-bro.png";
import carpoolUbi from "../../assets/images/Carpool-rafiki.png";
import plant from "../../assets/images/plant.png";
import YouTube from "../../assets/icons/YouTube.svg";
import Twiter from "../../assets/icons/Twiter.svg";
import LinkedIn from "../../assets/icons/LinkedIn.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import Facebook from "../../assets/icons/Facebook.svg";
import Languaje from "../../assets/icons/language.svg";
import logo from "../../assets/images/logo96px.png";
import horizontalVideo from "../../assets/video/horizontal.mp4";
import verticalVideo from "../../assets/video/vertical.mp4";
import useWindowWidth from "../../hooks/useWindowWidth";
import useWindowHeight from "../../hooks/useWindowHeight";

const languages = [
  { code: "es", phrase: "Muy pronto", flag: "🇪🇸" },
  { code: "en", phrase: "Coming soon", flag: "🇺🇸" },
  { code: "fr", phrase: "TraduccionPendiente", flag: "🇫🇷" },
  { code: "it", phrase: "TraduccionPendiente", flag: "🇮🇹" },
  { code: "de", phrase: "TraduccionPendiente", flag: "🇩🇪" },
  { code: "pt", phrase: "TraduccionPendiente", flag: "🇧🇷" },
  { code: "zh", phrase: "即TraduccionPendiente将推出", flag: "🇨🇳" },
];

const LandingPage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("es");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const width = useWindowWidth();
  const heigth = useWindowHeight();
  const heigthLogo = heigth * 0.5;
  const heigthCommingSoon = heigth * 0.1;

  const backgroundVideo = useMemo(() => {
    return heigth > width ? verticalVideo : horizontalVideo;
  }, [heigth, width]);

  // Alternar el menú de idiomas
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Manejar el cambio de idioma
  const handleLanguageChange = (code) => {
    setSelectedLanguage(code);
    setIsMenuOpen(false); // Cerrar el menú al seleccionar un idioma
  };

  // Obtener la frase en el idioma seleccionado
  const currentPhrase = languages.find(
    (lang) => lang.code === selectedLanguage
  )?.phrase;
  const selectedFlag = languages.find(
    (lang) => lang.code === selectedLanguage
  )?.flag;

  const plans = [
    {
      plan_name: "Plan Básico",
      price: 120,
      description:
        "Perfecto para conocer nuestro sistema y todo lo que puede ofrecer.",
      descriptionComplete: `
            *Hasta 50 Empleados
            *Funciones Principales
            -Registro de empleados
            -Planificación de viajes
            -Reserva de asientos.
            *Soporte Básico
            *Reporte Mensual Básico`,
    },
    {
      plan_name: "Plan Pro",
      price: 350,
      description:
        "Para equipos y empresas comprometidos con el medio ambiente.",
      descriptionComplete: `
            *Hasta 200 Empleados
            *Todas las Funciones del Plan Básico
            *Funciones Adicionales
            -Creación de grupos
            -Notificaciones personalizadas
            *Soporte Prioritario
            *Reporte Quincenal`,
    },
    {
      plan_name: "Plan Empresarial",
      price: 800,
      description: "Para grandes empresas enfocadas en cuidar el planeta.",
      descriptionComplete: `
            *Empleados Ilimitados
            *Todas las Funciones del Plan Avanzado
            *Funciones Exclusivas
            -Integración con otros sistemas
            -Personalización de la plataforma
            -Eventos especiales.
            *Soporte VIP: Asistencia 24/7 por correo electrónico, chat en vivo, y teléfono.
            *Reportes EN VIVO`,
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const formatDescription = (description) => {
    const lines = description.trim().split("\n");

    const formattedList = [];
    let subList = [];

    lines.forEach((line, index) => {
      const trimmedLine = line.trim();
      if (trimmedLine.startsWith("*")) {
        if (subList.length > 0) {
          formattedList.push(
            <ul key={`sub-${index}`}>
              {subList.map((item, subIndex) => (
                <li key={subIndex}>{item}</li>
              ))}
            </ul>
          );
          subList = [];
        }
        formattedList.push(<li key={index}>{trimmedLine.slice(1).trim()}</li>);
      } else if (trimmedLine.startsWith("-")) {
        subList.push(trimmedLine.slice(1).trim());
      }
    });

    if (subList.length > 0) {
      formattedList.push(
        <ul key="last-sub">
          {subList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }

    return formattedList;
  };
  return (
    <>
    {/*
      <header className="landing">
          <div className="title-landing">
              <img style={{ maxWidth: '4vw' }} src={logo} alt="amvi" />
              <div className="menu-header-landing">
                  <span>Viajes</span>
                  <span>Conducir</span>
                  <span>Planes</span>
                  <span>Empresas</span>
              </div>
          </div>
          <div className="buttons-header-landing">
              <div>
                  <img src={Languaje} alt="" />
                  <span>Es</span>
              </div>
              <button onClick={() => handleNavigation('/login')}>Iniciar Sesión</button>
              <button onClick={() => handleNavigation('/sign-up')}>Registrate</button>
          </div>
      </header>
      <main className="body-landing">
          <div className="main-landing">
              <h2>
                  Comparte tu&nbsp;
                  <br />
                  viaje, reduce tu&nbsp;
                  <br />
                  huella
              </h2>
              <br />
              <p>Simplifica tus desplazamientos diarios <br /> compartiendo tus viajes.</p>
              <button>Únete ahora</button>
          </div>
          <div className="invitation-landing">
              <p>
                  Únete a nuestra comunidad de carpooling&nbsp;
                  <br />
                  corporativo y transforma tu trayecto al trabajo&nbsp;
                  <br />
                  en una experiencia más económica, ecológica y&nbsp;
                  <br />
                  social. Comparte el viaje con compañeros de&nbsp;
                  <br />
                  trabajo y contribuye a reducir el tráfico y la&nbsp;
                  <br />
                  contaminación.
              </p>
              <p className="inivitation-tablet-compu">
                  Únete a nuestra comunidad de carpooling corporativo y transforma tu trayecto al trabajo en una experiencia más económica, ecológica y social. Comparte el viaje con compañeros de trabajo y contribuye a reducir el tráfico y la contaminación.
              </p>
          </div>
          <div className="iamge-percentages">
              <div className="landing-image">
                  <img src={carpooling} alt="" />
              </div>
              <div className="description-landing">
                  <div className="feature-container">
                      <span>50%</span>
                      <p>Ahorro en gastos de<br />transporte compartiendo<br />viajes.</p>
                  </div>
                  <div className="feature-container">
                      <span>75%</span>
                      <p>Disminución de emisiones<br />de CO2 por persona al<br />carpooling.</p>
                  </div>
                  <div className="feature-container">
                      <span>30%</span>
                      <p>Menos tiempo en tráfico<br />con carriles exclusivos<br />para carpooling.</p>
                  </div>
              </div>
          </div>
          <div className="description-landing">
              <p className="title-download">¿Cómo funciona?</p>
              <div className="step-one">
                  <p>Entra a<br />amvi</p>
                  <img src={carpoolUbi} alt="" />
              </div>
              <div className="step-two">
              <p>Comparte un<br />Viaje</p>
              <img style={{ maxHeight: '25vh' }} src={logo} alt="amvi" />
              </div>
              <div className="step-three">
                  <p>Ahorra y Proteje<br />al planeta</p>
                  <img src={plant} alt="" />
              </div>
              <div className="section-title">
                  <p>Nuestros Planes</p>
                  <span>Encuentra el plan perfecto para ti y para tu <br /> empresa</span>
              </div>
              <div className="subscription-section">
                  {plans.map((plan, index) => (
                      <div key={index} className="plan-card">
                          <div className="subscription-cost">
                              <label>{plan.plan_name}</label>
                              <p>${plan.price}<span>/mes</span></p>
                          </div>
                          <div className="get-subscription">
                              <p className="description-movil">{plan.description}</p>
                              <p className="description-tablet-compu">
                                  <span>Incluye</span>
                                  <br />
                                  <ul>
                                      {formatDescription(plan.descriptionComplete)}
                                  </ul>
                              </p>
                              <button>Conseguir {plan.plan_name}</button>
                          </div>
                      </div>
                  ))}
              </div>
          </div>
          <footer className="footer-landing">
              <div className="brand-name-footer">
                  <img style={{ maxHeight: '42vh' }} src={logo} alt="amvi" />
              </div>
              <p>Slogan de la empresa</p>
              <label>Compañía</label>
              <p>Inicio</p>
              <p>Quiénes Somos</p>
              <p>Contacto</p>
              <p>Trabaja con Nosotros</p>
              <label>Servicios</label>
              <p>Viaje</p>
              <p>Conducir</p>
              <p>Para Empresas</p>
              <div className="social-networks-footer">
                  <img src={YouTube} alt="" />
                  <img src={Twiter} alt="" />
                  <img src={LinkedIn} alt="" />
                  <img src={Instagram} alt="" />
                  <img src={Facebook} alt="" />
              </div>
              <div className="legality-options">
                  <span>Términos y Condiciones</span>
                  <span>Privacidad</span>
                  <span>Preguntas Frecuentes</span>
              </div>
              <div className="all-rights-reserved">
                  © 2024 amvi
              </div>
          </footer>
          <footer className="footer-landing-deskTablet">
              <div className="footer-main-desktop">
                  <div className="slogan-brand-desktop">
                      <div className="brand-name-desktop">
                      <img style={{ maxHeight: '42vh' }} src={logo} alt="amvi" />
                      </div>
                      <p>Slogan de la empresa</p>
                  </div>
                  <div className="container-company-services">
                      <div className="company-services-desktop">
                          <label>Compañía</label>
                          <span>Inicio</span>
                          <span>Quiénes Somos</span>
                          <span>Contacto</span>
                          <span>Trabaja con Nosotros</span>
                      </div>
                      <div className="company-services-desktop">
                          <label>Servicios</label>
                          <span>Viaje</span>
                          <span>Conducir</span>
                          <span>Para Empresas</span>
                      </div>
                  </div>
                  <div className="social-network-desktop">
                      <img src={YouTube} alt="" />
                      <img src={Twiter} alt="" />
                      <img src={LinkedIn} alt="" />
                      <img src={Instagram} alt="" />
                      <img src={Facebook} alt="" />
                  </div>
                  <div className="terms-conditions-desktop">
                      <p>Términos y Condiciones</p>
                      <p>Privacidad</p>
                      <p>Preguntas Frecuentes</p>
                  </div>
              </div>
              <div className="all-rights-reserved-desktop">
                  <p>© 2024 amvi</p>
              </div>
          </footer>
      </main>*/}
      {/* Temporal Landing */}
      {/* Video on autoplay with no controls from a url with a message on top saying coming soon and a logo at the top of the screen */}
      <div className="video-background">
        <video
          key={backgroundVideo}
          autoPlay
          muted
          loop
          className="background-video"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <div className="flag-selector">
          <button onClick={toggleMenu} className="flag-button">
            {selectedFlag}
          </button>
          {isMenuOpen && (
            <div className="flag-menu">
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  onClick={() => handleLanguageChange(lang.code)}
                  className="flag-option"
                >
                  {lang.flag}
                </button>
              ))}
            </div>
          )}
        </div>
        <h1
          className="overlay-text"
          style={{
            top: heigth <= 550 ? "7%" : "15%",
          }}
        >
          {currentPhrase}
        </h1>
        <img
          className="logo"
          src={logo}
          alt="amvi"
          style={{ height: heigthLogo }}
        />
        <div className="version-text">
          {/*p with position absolute p*/}
          <p style={{ zIndex: 100, position: 'absolute', bottom: '10px', right: '10px', fontSize: 16 , color: 'white'}}>v0.1.5</p>
        </div>
      </div>
    </>
  );
};

export { LandingPage };
