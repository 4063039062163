import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./plans.css";
import back from "../../../assets/icons/go-back.svg";

const getCSSVariable = (variable) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable);

const Plans = () => {
  const navigate = useNavigate();

  const handleNavigation = (path, mode) => {
    navigate(path, { state: { mode } });
  };
  const plans = [
    {
      plan_name: "Plan Básico",
      price: 120,
      description:
        "Perfecto para conocer nuestro sistema y todo lo que puede ofrecer.",
      descriptionComplete: `
            *Hasta 50 Empleados
            *Funciones Principales
            -Registro de empleados
            -Planificación de viajes
            -Reserva de asientos.
            *Soporte Básico
            *Reporte Mensual Básico`,
    },
    {
      plan_name: "Plan Pro",
      price: 350,
      description:
        "Para equipos y empresas comprometidos con el medio ambiente.",
      descriptionComplete: `
            *Hasta 200 Empleados
            *Todas las Funciones del Plan Básico
            *Funciones Adicionales
            -Creación de grupos
            -Notificaciones personalizadas
            *Soporte Prioritario
            *Reporte Quincenal`,
    },
    {
      plan_name: "Plan Empresarial",
      price: 800,
      description: "Para grandes empresas enfocadas en cuidar el planeta.",
      descriptionComplete: `
            *Empleados Ilimitados
            *Todas las Funciones del Plan Avanzado
            *Funciones Exclusivas
            -Integración con otros sistemas
            -Personalización de la plataforma
            -Eventos especiales.
            *Soporte VIP: Asistencia 24/7 por correo electrónico, chat en vivo, y teléfono.
            *Reportes EN VIVO`,
    },
  ];

  const formatDescription = (description) => {
    const lines = description.trim().split("\n");

    const formattedList = [];
    let subList = [];

    lines.forEach((line, index) => {
      const trimmedLine = line.trim();
      if (trimmedLine.startsWith("*")) {
        if (subList.length > 0) {
          formattedList.push(
            <ul key={`sub-${index}`}>
              {subList.map((item, subIndex) => (
                <li key={subIndex}>{item}</li>
              ))}
            </ul>
          );
          subList = [];
        }
        formattedList.push(<li key={index}>{trimmedLine.slice(1).trim()}</li>);
      } else if (trimmedLine.startsWith("-")) {
        subList.push(trimmedLine.slice(1).trim());
      }
    });

    if (subList.length > 0) {
      formattedList.push(
        <ul key="last-sub">
          {subList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }

    return formattedList;
  };

  const [isWide, setIsWide] = useState(window.innerWidth > 480);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 480);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="main-plans">
      <div
        onClick={() => {
          handleNavigation("/home");
        }}
        className="go-back-plans"
      >
        <img src={back} alt="" />
      </div>
      <div className="text-plans">
        Encuentra el plan perfecto para ti y <br /> para todo tu equipo.
      </div>
      <div className="plans-text">
        <label>Nuestros Planes</label>
        <p>Encuentra el plan perfecto para ti y para tu empresa</p>
      </div>
      <div>
        <div className="subscription-section">
          {plans.map((plan, index) => (
            <div key={index} className="plan-card">
              <div className="subscription-cost">
                <label>{plan.plan_name}</label>
                <p>
                  ${plan.price}
                  <span>/mes</span>
                </p>
              </div>
              <div
                className="get-subscription"
                style={{
                  backgroundColor: isWide
                    ? getCSSVariable("--background-step-two")
                    : getCSSVariable("--background-step"),
                }}
              >
                <p className="description-movil">{plan.description}</p>
                <p className="description-tablet-compu">
                  <span>Incluye</span>
                  <br />
                  <ul>{formatDescription(plan.descriptionComplete)}</ul>
                </p>
                <button
                  style={{ backgroundColor: "#1cd93c", color: "#fafafa" }}
                  onClick={() => {
                    handleNavigation(
                      window.innerWidth > 480
                        ? "/home/purchase-detail"
                        : "/home/purchase-detail"
                    );
                  }}
                >
                  Conseguir {plan.plan_name}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Plans };
