import React from "react";
import "./item-subscription-purchase-styles.css";
import subscriptionIcon from "../../assets/icons/arrow-up-circle.svg";
import rightIcon from "../../assets/icons/right.svg";

const ItemSubscriptionPurchase = () => {
  return (
    <>
      <div className="mobile-item-for-payment-history">
        <div className="name-and-date-of-payment-on-mobile">
          <img src={subscriptionIcon} alt="subscription icon" />
          <div className="mobile-payment-data">
            <label>Compra de Suscripción</label>
            <span>15/07/2024</span>
          </div>
        </div>
        <img src={rightIcon} alt="right icon" />
      </div>
    </>
  );
};

export { ItemSubscriptionPurchase };
