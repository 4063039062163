import React from "react";
import "./payment-history-item.css";
import upArrowIcon from "../../assets/icons/arrow-up-circle.svg";
import rightIcon from "../../assets/icons/right.svg";

const PaymentHistoryItem = ({pay, paymentDate}) => {
  return (
    <div className="payment-history-item">
      <div className="icon-description-history-item">
        <img src={upArrowIcon} alt="" />
        <section className="description-history-item">
          <label>{pay}</label>
          <label>{paymentDate}</label>
        </section>
      </div>
      <img src={rightIcon} alt="" />
    </div>
  );
};

export { PaymentHistoryItem };
